import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Area } from '@wix/ambassador-table-reservations-v1-area/types';
import { listAreas } from '@wix/ambassador-table-reservations-v1-area/http';

const getAreas = async (
  flowAPI: PlatformControllerFlowAPI,
): Promise<Area[]> => {
  const res = await flowAPI.httpClient.request(listAreas({}));
  return res.data.area ?? [];
};

export const areasService = {
  getAreas,
};
