import { Location } from '@wix/ambassador-table-reservations-v1-location/types';
import { Area } from '@wix/ambassador-table-reservations-v1-area/types';

import { areasService } from '../../services/areasService';
import { locationsService } from '../../services/locationsService';
import { reservationsService } from '../../services/reservationsService';
import { toJSON } from '../../utils/toJSON';
import model from './model';

const getFirstAreaByLocationId = (
  areas: Area[],
  currentLocationId: string,
): Area | undefined => {
  return areas.find((it) => it.locationId === currentLocationId);
};

const getRange = ({
  start,
  end,
  step = 1,
  size = Math.ceil((end - start) / step) + 1,
}) => Array.from({ length: size }, (_, i) => i * step + start);

function getCurrentUTCDate(
  date?: string | Date,
): {
  year: number;
  month: number;
  date: number;
  hours: number;
  minutes: number;
} {
  const _date = new Date(date || new Date());

  return {
    year: _date.getUTCFullYear(),
    month: _date.getUTCMonth(),
    date: _date.getUTCDate(),
    hours: _date.getUTCHours(),
    minutes: _date.getUTCMinutes(),
  };
}

export default model.createController(
  ({ $w, $widget, flowAPI, $bind, initState }) => {
    const currentDateTime = new Date();

    const { state } = initState<{
      locations: Location[];
      areas: Area[];
      currentLocationId?: string;
      currentPartySize?: number;
      currentDate: Date;
      currentTime: string;
    }>({
      locations: [],
      areas: [],
      currentLocationId: undefined,
      currentPartySize: undefined,
      currentDate: currentDateTime,
      currentTime: `${currentDateTime.getUTCHours()}:${
        currentDateTime.getUTCMinutes() < 10 ||
        currentDateTime.getUTCMinutes() === 0
          ? '0' + currentDateTime.getUTCMinutes()
          : currentDateTime.getUTCMinutes()
      }`,
    });

    const pageReady = async () => {
      $widget.fireEvent('widgetLoaded', { a: 'test' });

      const [locations, areas] = await Promise.all([
        locationsService.getLocations(flowAPI),
        areasService.getAreas(flowAPI),
      ]);

      console.log('--- 0', toJSON(state.locations));
      state.locations = locations;
      state.areas = areas;

      // locations dropdown
      $bind('#dropdown1', {
        options: () => {
          console.log('--- 1', toJSON(state.locations));

          return state.locations.map((loc) => {
            return { label: loc.name, value: loc.id };
          });
        },
        selectedIndex: () => 0,
        onChange: (event) => {
          state.currentLocationId = event.target.value;
        },
      });

      // areas dropdown
      $bind('#dropdown2', {
        options: () => {
          const area = getFirstAreaByLocationId(
            state.areas,
            state.currentLocationId,
          );

          const range = getRange({
            start: area?.partiesSize?.min ?? 0,
            end: area?.partiesSize?.max ?? 0,
          });

          return range.map((i) => {
            return {
              label: flowAPI.translations.t('find-table.parties-size', {
                count: i,
              }),
              value: i.toString(),
            };
          });
        },
        selectedIndex: () => 0,
        onChange: (event) => {
          console.log('BEFORE', state.currentPartySize);
          state.currentPartySize = event.target.value;
          console.log('AFTER', state.currentPartySize);
        },
      });

      // date datepicker
      $bind('#datePicker1', {
        onChange: (event) => {
          const _date = getCurrentUTCDate(event.target.value);
          console.log(_date);
          state.currentDate = event.target.value;
        },
        minDate: () => new Date(state.currentDate),
        value: () => new Date(state.currentDate),
      });

      // time timepicker
      $bind('#timePicker1', {
        onChange: (event) => {
          state.currentTime = event.target.value;
        },
        value: () => `${state.currentTime}`,
      });

      $bind('#button1', {
        onClick: async () => {
          const areaId = getFirstAreaByLocationId(
            state.areas,
            state.currentLocationId,
          ).id;
          const date = new Date(state.currentDate);
          const partySize = state.currentPartySize;

          console.log(
            '=====',
            '\nareaId =',
            areaId,
            '\npartySize =',
            partySize,
            '\ndate =',
            date,
            '=====',
          );

          const slots = await reservationsService.getTimeSlots(flowAPI, {
            areaId,
            date,
            partySize,
            slotsBefore: 7,
            slotsAfter: 7,
          });

          flowAPI.controllerConfig.platformAPIs.storage.local.setItem(
            'tmp_slot',
            JSON.stringify(slots),
          );

          flowAPI.controllerConfig.wixCodeApi.location.to(
            `/time-slots?areaId=${areaId}&date=${date}&partySize=${partySize}`,
          );
        },
      });

      state.currentLocationId = $w('#dropdown1').value;
      state.currentPartySize = parseInt($w('#dropdown2').value, 10);
    };

    return {
      pageReady,
      exports: {},
    };
  },
);
