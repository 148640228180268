import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import model from './model';
import format from 'date-fns/format';

export default model.createController(
  ({ $w, $widget, flowAPI, $bind, initState }) => {
    const { state } = initState<{}>({});
    const defaultSlots = {
      timeSlots: [
        {
          startDate: '2022-02-01T11:15:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T11:30:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T11:45:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T12:00:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T12:15:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T12:30:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T12:45:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T13:00:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T13:15:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T13:30:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T13:45:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T14:00:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T14:15:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T14:30:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
        {
          startDate: '2022-02-01T14:45:00Z',
          durationMinutes: 90,
          status: 'AVAILABLE',
        },
      ],
    };
    // todo: remove default slots, just for test now

    const pageReady = async () => {
      $widget.fireEvent('widgetLoaded', { a: 'test' });

      const timeSlotsFromLS: any =
        flowAPI.controllerConfig.platformAPIs.storage.local.getItem(
          'tmp_slot',
        ) || defaultSlots;

      $bind('#repeater1', {
        data: () =>
          (JSON.parse(timeSlotsFromLS)?.timeSlots || []).map((item, ind) => ({
            ...item,
            _id: 'item-' + ind,
          })),
        item(itemData: any, $bindItem) {
          $bindItem('#timePicker1', {
            value: () => format(new Date(itemData.startDate), 'HH:mm'),
            disabled: () => itemData.status !== 'AVAILABLE',
          });
        },
      });
    };
    return {
      pageReady,
      exports: {},
    };
  },
);
