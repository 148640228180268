import {
  GetAvailableTimeSlotsRequest,
  TimeSlot,
} from '@wix/ambassador-table-reservations-v1-time-slot/types';
import { getAvailableTimeSlots } from '@wix/ambassador-table-reservations-v1-time-slot/http';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

const getTimeSlots = async (
  flowAPI: PlatformControllerFlowAPI,
  params: GetAvailableTimeSlotsRequest,
): Promise<TimeSlot[]> => {
  const res = await flowAPI.httpClient.request(getAvailableTimeSlots(params));

  return res.data.timeSlots ?? [];
};

export const reservationsService = {
  getTimeSlots,
};
