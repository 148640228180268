import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { listLocations } from '@wix/ambassador-table-reservations-v1-location/http';
import { Location } from '@wix/ambassador-table-reservations-v1-location/types';

const getLocations = async (
  flowAPI: PlatformControllerFlowAPI,
): Promise<Location[]> => {
  const res = await flowAPI.httpClient.request(listLocations({}));
  return res.data.location ?? [];
};

export const locationsService = {
  getLocations,
};
